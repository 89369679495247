import React, { useState, useContext, useEffect, useCallback } from "react"
import find from "lodash/find"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import { StoreContext } from "../../context/store-context"
import { AddToCart } from "../add-to-cart"

const ProductForm = ({ product }) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    productTypeSlug,
    handle,
  } = product
  const [variant, setVariant] = useState({ ...initialVariant })
  const [size, setSize] = useState(
    initialVariant.selectedOptions.find((item) => item.name === "Size")?.value
  )
  const [quantity, setQuantity] = useState(1)
  const { client } = useContext(StoreContext)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )
  useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  useEffect(() => {
    if (size) {
      const newVariant = variants.find((item) =>
        item.selectedOptions.some((option) => option.value === size)
      )
      setVariant(newVariant)
    } else {
      const newVariant = variants.find((item) =>
        item.selectedOptions.every(({ value }) => value === size)
      )
      if (newVariant) setVariant(newVariant)
      else {
        const closeVariant = variants.find((item) =>
          item.selectedOptions.every(({ value }) => value === size)
        )
        if (closeVariant) setVariant(closeVariant)
      }
    }
  }, [size])

  const handleQuantityChange = (value) => {
    if (value > 0 && value < 11) setQuantity(value)
  }

  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    })
    if (match === undefined) return true
    if (match.availableForSale === true) return false
    return true
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box>
        {options.map(({ id, name, values }, index) => (
          <React.Fragment key={id}>
            {name === "Size" && values.length > 0 ? (
              <>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  gap={1}
                  name={name}
                  key={id}
                >
                  {values.map((value) => (
                    <button
                      key={`${name}-${value}`}
                      onClick={() =>
                        checkDisabled(name, value) ? null : setSize(value)
                      }
                      className={`size-button ${
                        value === size ? "selected-size" : ""
                      } ${
                        checkDisabled(name, value) ? "size-button-disabled" : ""
                      }`}
                    >
                      {value}
                    </button>
                  ))}
                </Box>
              </>
            ) : null}
          </React.Fragment>
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <div>QUANTITY</div>
        <Box display="flex" justifyContent="center">
          <button
            className="quantity-control"
            onClick={() => handleQuantityChange(quantity - 1)}
          >
            -
          </button>
          <div className="quantity-box">{quantity}</div>
          <button
            className="quantity-control"
            onClick={() => handleQuantityChange(quantity + 1)}
          >
            +
          </button>
        </Box>
      </Box>
      <Box textAlign="center">
        <AddToCart
          variantId={variant.storefrontId}
          quantity={quantity}
          available={available}
          path={productTypeSlug + "/" + handle}
        />
      </Box>
    </Box>
  )
}

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductForm
