import React from "react"
import FullscreenChild from "./FullscreenChild"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-plugin-sanity-image"
import { Box } from "@mui/material"

const SizeChart = ({ setSize, size, type, v }) => {
  const {
    sanitySiteSettings: { size_chart },
  } = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        size_chart {
          _key
          _type
          size_chart_name
          size_chart_image {
            ...ImageWithPreview
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
          }
        }
      }
    }
  `)
  const sizeChartPic = size_chart.find((item) => item.size_chart_name === type)
  if (!sizeChartPic || !sizeChartPic?.size_chart_image) return null
  return (
    <Box display="flex" justifyContent="space-between">
      <button
        className="heading-4 clickable underscore"
        onClick={() => setSize(!size)}
      >
        SIZING CHART
        <FullscreenChild show={size} onClick={() => setSize(!size)}>
          <Image
            {...sizeChartPic.size_chart_image}
            style={{ maxWidth: 600 }}
            alt="Sizing Chart"
          />
        </FullscreenChild>
      </button>
    </Box>
  )
}

export default SizeChart
