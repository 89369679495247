import React, { useState, useEffect } from "react"

const FullscreenChild = ({ children, onClick, show }) => {
  const [realShow, setRealShow] = useState(show)
  const [op, setOp] = useState(0)
  useEffect(() => {
    if (show) {
      setRealShow(show)
      setOp(0)
      setTimeout(() => {
        setOp(1)
      }, 1)
    } else {
      setOp(0)
      setTimeout(() => {
        setRealShow(show)
      }, 500)
    }
  }, [show])
  return (
    <div
      onClick={onClick}
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        display: realShow ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        left: 0,
        top: 0,
        opacity: op,
        pointerEvents: !show ? "none" : "all",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        transitionDuration: "0.5s",
      }}
    >
      {children}
    </div>
  )
}

export default FullscreenChild
